export function createSeoData(data, modifiedDate, createdAt) {
    if (!data) {
        return {};
    }
    return {
        title: data.title,
        description: data.description,
        keywords: data.keywords,
        image: data.image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src,
        publishDate: data.publishDate ?? createdAt,
        modifiedDate: modifiedDate ?? data.modifiedDate,
        author: data.author?.name
    };
}
