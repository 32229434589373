import React from 'react';
import classNames from 'classnames';
import {BlockTitle} from "../BlockTitle";
import {BlockLead} from "../BlockLead";

import * as styles from './Block.module.scss';

export function Block({className, withTypicalMargin = false, appearance, ...otherProps}) {
    const classes = classNames(styles.root, className, withTypicalMargin && styles.withTypicalMargin)
    return <div className={classes} data-appearance={appearance} {...otherProps} />;
}

export function sectionBackgroundToAppearance(background) {
    if (background === 'gray' || background === 'grey') {
        return 'subtle';
    }

    if (background === 'blue') {
        return 'blue';
    }

    if (background === 'primary') {
        return 'primary';
    }
}
